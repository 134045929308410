<template>
  <v-container fluid>
    <v-row v-if="dataLoaded" class="pt-4">
      <v-col cols="12" class="text-center">
        <h4 class="font-weight-regular label--text">
          {{ $t("home_important_management_graphs") }}
        </h4>
      </v-col>

      <v-col cols="12">
        <HomeCardMonthlySalesAchievementRate />
      </v-col>

      <v-col cols="12">
        <HomeCardMonthlyFLRatioReport />
      </v-col>

      <v-col cols="12">
        <HomeCardDepositBalance />
      </v-col>

      <v-col cols="12" class="text-center">
        <h4 class="font-weight-regular label--text">
          {{ $t("home_other_indicators") }}
        </h4>
      </v-col>

      <v-col cols="12">
        <HomeCardSalesAndCosts />
      </v-col>

      <v-col cols="12">
        <HomeCardNotifications />
      </v-col>

      <v-col cols="12">
        <HomeListLinkPosts />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HomeCardMonthlySalesAchievementRate from "@/components/layout/home/HomeCardMonthlySalesAchievementRate";
import HomeCardMonthlyFLRatioReport from "@/components/layout/home/HomeCardMonthlyFLRatioReport";

import HomeCardDepositBalance from "@/components/layout/home/HomeCardDepositBalance";
import HomeCardSalesAndCosts from "@/components/layout/home/HomeCardSalesAndCosts";
import HomeCardNotifications from "@/components/layout/home/HomeCardNotifications";
import HomeListLinkPosts from "@/components/layout/home/HomeListLinkPosts";

export default {
  name: "Home",
  components: {
    HomeListLinkPosts,
    HomeCardNotifications,
    HomeCardSalesAndCosts,
    HomeCardDepositBalance,
    HomeCardMonthlySalesAchievementRate,
    HomeCardMonthlyFLRatioReport
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch("USER_REPORT_GET")
      .then(() => {
        this.dataLoaded = true;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: false,
      dataLoaded: false
    };
  }
};
</script>

<style scoped></style>
