<template>
  <div>
    <v-divider></v-divider>
    <v-card
      flat
      :color="odd ? 'item-bank-odd-background' : ''"
      class="rounded-0"
    >
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-right pl-0">
            <h4
              class="text-left font-weight-regular bank-balance-title-text main--text lighten-2"
            >
              {{ name }}
            </h4>
            <h4 :class="balanceClasses">
              {{ balanceText }}
            </h4>
            <h6 class="font-weight-regular text-caption main--text lighten-4">
              {{ $t("last_updated", { date: date }) }}
            </h6>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { toCurrency } from "@/filters";

export default {
  name: "ListItemBank",
  props: {
    name: {
      type: String,
      default: "",
      required: true
    },
    balance: {
      type: Number,
      default: 0,
      required: true
    },
    date: {
      type: String,
      default: null,
      required: true
    },
    synchronized: {
      type: Boolean,
      default: false,
      required: false
    },
    odd: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    balanceClasses: function() {
      return this.synchronized
        ? "font-weight-bold text-h6 main--text"
        : "font-weight-bold text-subtitle-1 item-bank-please-sync--text";
    },
    balanceText: function() {
      return this.synchronized
        ? toCurrency(this.balance)
        : "Please synchronize";
    }
  }
};
</script>

<style scoped>
.bank-balance-title-text {
  font-size: 16px;
}

.item-bank-odd-background {
  background-color: #f6f6f6 !important;
}

.item-bank-please-sync--text {
  color: #481414 !important;
}
</style>
