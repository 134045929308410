<template>
  <CardBase
    number="3"
    :title="$t('home_deposit_balance_card_title')"
    route-destination="/balance"
    :route-text="$t('home_deposit_balance_card_route_text')"
    :help-button="false"
    :show-date="false"
  >
    <template v-slot:content-body>
      <div>
        <BankBalanceChart :chart-data="chartData"></BankBalanceChart>
      </div>

      <v-row class="mt-2 mb-4" align="center" justify="center">
        <v-col cols="6" class="text-right">
          <h4 class="font-weight-bold main--text text-subtitle-1">
            {{ $t("total_balance") }}
          </h4>
        </v-col>
        <v-col cols="6" class="text-left">
          <h4 class="font-weight-bold main--text text-h5">
            {{ getUserReport.bank_balance.total | toCurrency }}
          </h4>
        </v-col>
      </v-row>

      <div>
        <ListItemBank
          v-for="(item, index) in banks"
          :key="index"
          :name="item.name"
          :balance="item.balance"
          :date="item.updated"
          :synchronized="item.sync"
          :odd="index % 2 !== 0"
        />
        <v-divider></v-divider>
      </div>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import ListItemBank from "@/components/ui/ListItemBank";
import { mapGetters } from "vuex";
import BankBalanceChart from "@/components/ui/BankBalanceChart";

export default {
  name: "HomeCardDepositBalance",
  components: { BankBalanceChart, ListItemBank, CardBase },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUserReport"]),
    banks() {
      return this.getUserReport.bank_balance.banks.map(item => {
        return {
          id: item.id,
          name: item.name,
          balance: item.balance,
          updated: item.last_updated_date,
          sync: true
        };
      });
    },
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: [
          {
            label: "Balance",
            borderColor: "#0094FF",
            pointBackgroundColor: "#0094FF",
            pointBorderColor: "#0094FF",
            pointHoverBackgroundColor: "#0094FF",
            pointHoverBorderColor: "#0094FF",
            pointHitRadius: 25,
            data: this.chartValues
          }
        ]
      };
    },
    chartValues() {
      if (!this.getUserReport) return [];

      return this.getUserReport.bank_balance.monthly_balances.map(item => {
        return item.balance;
      });
    },
    chartLabels() {
      if (!this.getUserReport) return [];
      return this.getUserReport.bank_balance.monthly_balances.map(item => {
        return item.month.name;
      });
    }
  }
};
</script>

<style scoped></style>
