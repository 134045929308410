<template>
  <CardBase
    :title="$t('home_sales_costs_card_title')"
    route-destination="/sales-costs"
    :route-text="$t('home_sales_costs_card_route_text')"
    :custom-date="date"
    modal-title="売上・コストの月次推移"
    modal-component="HomeSalesCostsModal"
  >
    <template v-slot:content-body>
      <SalesAndCostsChart :chart-data="chartData" :items-data="salesAndCosts" />
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import SalesAndCostsChart from "@/components/ui/SalesAndCostsChart";
import { mapGetters } from "vuex";

export default {
  name: "HomeCardSalesAndCosts",
  components: { SalesAndCostsChart, CardBase },
  created() {
    this.mapValues();
  },
  computed: {
    date() {
      return this.getUserReport.date;
    },
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: this.chartDatasets
      };
    },
    ...mapGetters(["getUserReport"]),
    maxValue() {
      return this.getUserReport.max_month_sale >
        this.getUserReport.max_month_cost
        ? this.getUserReport.max_month_sale
        : this.getUserReport.max_month_cost;
    }
  },
  data() {
    return {
      chartDatasets: [],
      chartLabels: [],
      salesAndCosts: []
    };
  },
  methods: {
    calculatePercentage(maxValue, value) {
      return Math.round((value / maxValue) * 100);
    },

    mapValues() {
      let salesData = [];
      let costsData = [];
      let salesAndCosts = [];
      let chartLabels = [];

      for (const deal of this.getUserReport.monthly_deals) {
        salesData.push(this.calculatePercentage(this.maxValue, deal.sale));
        costsData.push(this.calculatePercentage(this.maxValue, deal.cost));
        salesAndCosts.push({ sales: deal.sale, costs: deal.cost });
        chartLabels.push(deal.month.name);
      }

      this.chartDatasets = [
        {
          label: "Sales",
          backgroundColor: "#576dd9",
          borderColor: "#576dd9",
          data: salesData,
          barPercentage: 1
        },
        {
          label: "Costs",
          backgroundColor: "#E9CF75",
          borderColor: "#E9CF75",
          data: costsData,
          barPercentage: 1
        }
      ];

      this.salesAndCosts = salesAndCosts;
      this.chartLabels = chartLabels;
    }
  }
};
</script>

<style scoped></style>
