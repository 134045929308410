<template>
  <CardBase
    number="2"
    :title="$t('home_fl_ratio_card_title', { month: month })"
    route-destination="/fl-ratio"
    :route-text="$t('home_fl_ratio_card_route_text')"
    :custom-date="this.getUserReport.month.fl_costs.date"
    modal-title="FL比率"
    modal-component="HomeFLRatioModal"
  >
    <template v-slot:content-body>
      <v-container>
        <FLRatioCumulativeChart :chart-data="chartData" :fl-ratio="flRatio" />
      </v-container>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import FLRatioCumulativeChart from "@/components/ui/FLRatioCumulativeChart";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  name: "MonthlyFLRatioReport",
  components: { FLRatioCumulativeChart, CardBase },
  computed: {
    ...mapGetters(["getUserReport"]),
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: this.chartDatasets
      };
    },
    foodPercentage() {
      return this.getUserReport.month.fl_costs.f_cost.percentage;
    },
    laborPercentage() {
      return this.getUserReport.month.fl_costs.l_cost.percentage;
    },
    otherPercentage() {
      return this.getUserReport.month.other_costs.percentage;
    },
    flRatio() {
      return this.getUserReport.month.fl_costs.total.percentage;
    },
    chartDatasets() {
      return [
        {
          label: "Data One",
          backgroundColor: ["#069821", "#F99F1C", "#C8C7B6"],
          data: [
            this.foodPercentage,
            this.laborPercentage,
            this.otherPercentage
          ]
        }
      ];
    },
    month() {
      return dayjs(this.getUserReport.month.fl_costs.date).format("M");
    }
  },
  data() {
    return {
      chartLabels: ["Food costs", "Labor costs", "Others"]
    };
  }
};
</script>

<style scoped></style>
