<template>
  <CardBase
    number="1"
    :title="$t('home_sales_card_title', { month: month })"
    route-destination="goals"
    :route-text="$t('home_sales_card_route_text')"
    :content-title="monthTitle"
    :date="date"
    :show-date="false"
    modal-title="売上数値の見方"
    modal-component="HomeSalesFigureModal"
  >
    <template v-slot:content-body>
      <v-row>
        <v-col cols="12">
          <ListItemSalesFigures
            icon="$flag"
            color="primary"
            :title="
              $t('sales_figure_performance', {
                month: getUserReport.month.name
              })
            "
            :value="getUserReport.month.sale_to_date"
            :value-subtitle="
              $t('sales_achievement_rate_from_target', {
                percentage: getUserReport.month.success_percentage
              })
            "
          />
        </v-col>

        <v-col cols="12">
          <div class="text-center">
            <AchievementRateChart
              :total="getUserReport.month.goal_progress_percentage"
              :progress="getUserReport.month.actual_progress_percentage"
              :success="getUserReport.month.success_percentage"
            />
          </div>
        </v-col>
      </v-row>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import ListItemSalesFigures from "@/components/ui/ListItemSalesFigures";
import AchievementRateChart from "@/components/ui/AchievementRateChart";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";

export default {
  name: "SalesAchievementRate",
  components: { AchievementRateChart, ListItemSalesFigures, CardBase },
  computed: {
    date() {
      return dayjs(this.getUserReport.date).format("YYYY-MM-DD");
    },
    month() {
      return dayjs(this.getUserReport.date).format("M");
    },
    monthTitle() {
      return this.$t("home_sales_card_subtitle", {
        date: dayjs(this.getUserReport.date).format("M月D日")
      });
    },
    ...mapGetters(["getUserReport"])
  }
};
</script>

<style></style>
