<template>
  <v-row>
    <v-col cols="12">
      <ChartBankBalance :chart-data="chartData"> </ChartBankBalance>
    </v-col>

    <div class="card-tooltip" id="chartBankBalanceTooltip"></div>
  </v-row>
</template>

<script>
import ChartBankBalance from "@/components/charts/ChartBankBalance";
export default {
  name: "BankBalanceChart",
  components: { ChartBankBalance },
  props: {
    chartData: {
      type: Object,
      default: () => {},
      required: true
    }
  }
};
</script>

<style scoped>
.card-tooltip {
  position: absolute;
  opacity: 0;
  left: 160px;
  top: 125px;
  min-width: 125px;
  padding: 6px 6px;
  border-style: solid;
  border-radius: 5px !important;
  border-width: 1px !important;
  border-color: #dfdfdf !important;
  background-color: #eeeeee !important;
  /*box-shadow: 1px 1px 3px 1px #d7d7d7;*/
}
</style>
