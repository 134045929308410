<script>
import { Line, mixins } from "vue-chartjs";
import { formatThousands } from "@/filters";

const { reactiveProp } = mixins;

export default {
  name: "ChartBankBalance",
  extends: Line,
  mixins: [reactiveProp],
  data() {
    return {
      chartOptions: {
        fill: true,
        title: {
          display: true,
          text: ""
        },
        layout: {
          padding: {
            left: 20,
            right: 20
          }
        },
        responsive: true,
        devicePixelRatio: 1,
        maintainAspectRatio: true,
        showTooltips: false,
        tooltips: {
          enabled: false,
          mode: "point",
          backgroundColor: "#EEEEEE",
          titleFontFamily: "Roboto",
          titleFontColor: "#000",
          bodyFontFamily: "Roboto",
          bodyFontColor: "#000",
          caretSize: 0,
          displayColors: false,
          borderColor: "#DFDFDF",
          borderWidth: 1,
          cornerRadius: 5,
          custom: this.renderTooltips
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontSize: 14,
            fontFamily: "Roboto"
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                zeroLineColor: "transparent",
                display: false,
                drawOnChartArea: false
              },
              ticks: {
                fontColor: "#444444",
                fontFamily: "Roboto",
                fontSize: 14
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: "transparent",
                display: false,
                drawBorder: false,
                zeroLineColor: "#ccc",
                zeroLineWidth: 2
              },
              display: true,
              ticks: {
                display: false,
                beginAtZero: true
              }
            }
          ]
        },
        plugins: {
          datalabels: false,
          customAnnotationLabel: false,
          deferred: {
            xOffset: 150,
            yOffset: "50%",
            delay: 500
          }
        }
      }
    };
  },
  mounted() {
    this.chartData.datasets[0].backgroundColor = this.createGradientFill(
      this.$refs.canvas
    );
    this.$refs.canvas.height = 300;
    this.renderChart(this.chartData, this.chartOptions);
  },
  methods: {
    createGradientFill(canvas) {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 0, 0, 450);
      gradientStroke.addColorStop(0, "#00C2FF");
      gradientStroke.addColorStop(0.5, "#ffffff");

      return gradientStroke;
    },
    renderTooltips(tooltipModel) {
      let tooltipEl = document.getElementById("chartBankBalanceTooltip");
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
      let dataIndex = tooltipModel.dataPoints[0].index;

      if (tooltipModel.body) {
        let item = this.chartData.datasets[0].data[dataIndex];
        tooltipEl.innerHTML = `<div class="font-weight-medium main--text lighten-2 text-heading-1">
          ${this.$t("balance")}:
          <span class="main--text lighten-1">${formatThousands(item)}円</span >
          </div>`;
      }

      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = "absolute";
      if (dataIndex < 3) {
        tooltipEl.style.left = tooltipModel.caretX + 40 + "px";
      } else {
        tooltipEl.style.left = tooltipModel.caretX - 135 + "px";
      }
      tooltipEl.style.top = tooltipModel.y + 75 + "px";
      tooltipEl.style.pointerEvents = "none";
    }
  }
};
</script>

<style scoped></style>
